import React from 'react';
import '../assests/styles/Videos.scss';
import HomeIcon from './HomeIcon';

// Sample Videos data (list of YouTube short video IDs)
const videos = [
    {
        id: 'mq4PtFLG1Qk' // Replace with actual YouTube video IDs
    },
    {
        id: 'uWnBxiMcoZE' // Replace with actual YouTube video IDs  OtxHF95ZvCw
    },
    {
        id: 'T6TSBFAwQQ8'
    },
    {
        id: 'XNvcbOy0w4E'
    },
    {
        id: 'NwADZFeBvdA'
    },
    {
        id: 'nWKzezOikaY'
    },
    {
        id: 'NqbZ4A02DuA'
    },
    {
        id: 'Sfx5z7Xea7c'
    }, 
    {
        id: 'Z6dApMZxgq8'
    },
    {
        id: 'BXW5DnI-VM4'
    }, 
    {
        id: 'uNV9XzH6OYU'
    },
    {
        id: 'mAMGa65v3cw'
    },
    {
        id: 'zTsrZoOUkaM'
    },
    {
        id: 'QezM04XENq8'
    },
    {
        id: 'uGqxnz4PMWo'
    },
    {
        id: 'fwREFgL1zwI'
    },
    {
        id: '9bNZKkeJVyo'
    },
    {
        id: 'Qor9qORhd_Y'
    },
    {
        id: 'XaZ9YngLV6U'
    },
];

const Videos = () => {
    return (
        <div>
            <HomeIcon />
            <div className="Videos-Page">
                <div className='Videos-Title'>
                    <h1 className="Videos-Title0">AmongIslam</h1>
                    <h1 className="Videos-Title1">Videos</h1>
                </div>
                <div className="Videos-Container">
                    {videos.map(video => (
                        <div className="video-item" key={video.id}>
                            <iframe
                                width="100%"
                                // height="315"
                                src={`https://www.youtube.com/embed/${video.id}`}
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Videos;
