import React, { useState } from "react";
import "../assests/styles/Series.scss";
import TheTaleofHumanity from "../assests/images/TheTaleofHumanity.jpg";

const series = [
    {
        image: TheTaleofHumanity,
        name: "The Tale of Humanity",
        playlistLink:
            "https://www.youtube.com/playlist?list=PLA7xy5NO-waG5BWQN2-V-kkJVvFXx0yFr",
        episodes: [
            {
                title: "1 - The Story of Adam & Hawa AS: The Beginning of Humanity",
                link: "https://www.youtube.com/watch?v=cG9jrH65RH0",
            },
        ],
    },
];

const Series = () => {
    const [activeModal, setActiveModal] = useState(null);

    const openModal = (index) => {
        setActiveModal(index);
    };

    const closeModal = () => {
        setActiveModal(null);
    };

    return (
        <div className="series">
            <h2 className="section-title">SERIES</h2>
            <div className="series-section">
                <div className="series-container">
                    {series.map((serie, index) => (
                        <div className="series-card" key={index}>
                            <a
                                className="series-link"
                                href={serie.playlistLink}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    src={serie.image}
                                    alt={`${serie.name}`}
                                    className="series-image"
                                />
                                <h3 className="series-title">{serie.name}</h3>
                            </a>
                            <button
                                className="dropdown-button"
                                onClick={() => openModal(index)}
                            >
                                Episodes
                            </button>
                        </div>
                    ))}
                </div>
            </div>

            {/* Modal */}
            {activeModal !== null && (
                <div className="modal-overlay" onClick={closeModal}>
                    <div
                        className="modal-content"
                        onClick={(e) => e.stopPropagation()} // Prevent modal close when clicking inside
                    >
                        <div className="modal-header">
                            <img
                                src={series[activeModal].image}
                                alt={`${series[activeModal].name}`}
                                className="modal-image"
                            />
                            <h2 className="modal-title">{series[activeModal].name}</h2>
                        </div>
                        <ul className="modal-episode-list">
                            {series[activeModal].episodes.map((episode, idx) => (
                                <li key={idx}>
                                    <a
                                        href={episode.link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="episode-link"
                                    >
                                        {episode.title}
                                    </a>
                                </li>
                            ))}
                        </ul>
                        <button className="close-modal-button" onClick={closeModal}>
                            Close
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Series;
